import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import Arrow from "../images/arrow.svg"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Nie znaleziono" />
    <section>
      <h1>404: Nie znaleziono</h1>
      <p>Właśnie wybrałeś trasę, która nie istnieje ...smuteczek.</p>
      <Link style={{display:'block', margin:'auto', width:'fit-content'}} to="/"><button className="kmf-button">Powrót<img src={Arrow} alt="strzałka" /></button></Link>
    </section>
  </Layout>
)

export default NotFoundPage
